import React from "react";
import { TabContent, TabLabel, TabWrapper } from "../global/AppTabs";

export const OurServicesBuyPlanPayments = ({
  selectedPlan,
  selectPayment,
  selectedPayment,
  onNext,
}) => {
  function onSelectPayment(name) {
    selectPayment(selectedPlan.payments.find((p) => p.name === name));
  }
  return (
    <TabWrapper
      activeTabClass="border-2 border-crimson-ffs font-bold text-crimson-ffs"
      tabClass="grid py-3 px-2 text-left rounded-lg bg-white border-2"
      value={selectedPlan.payments[0].name}
      onChange={onSelectPayment}
    >
      <div className="md:grid md:grid-cols-2 md:gap-11 mt-11">
        <div>
          <img
            src={selectedPlan.icon}
            alt={`icon ${selectedPlan.title}`}
            className="w-12 absolute top-4 md:top-11 right-4 md:left-11"
          />
          <h3 className="mb-8 text-crimson-ffs md:pt-8">
            {selectedPlan.title}
          </h3>
          <p>{selectedPlan.description}</p>
          <div>
            <div className="my-8">
              <h4 className="text-crimson-ffs font-bold mb-5">Services:</h4>
              <ol className="list-disc pl-7">
                {selectedPlan?.services?.map((e, i) => (
                  <li key={i}>{e}</li>
                ))}
              </ol>
            </div>

            {selectedPayment?.include?.length && (
              <div className="mb-8">
                <h4 className="mb-5 font-bold text-crimson-ffs">Includes:</h4>
                {selectedPlan.payments.map((payment) => (
                  <TabContent
                    key={payment.name}
                    name={payment.name}
                    className="flex flex-col justify-center"
                  >
                    <ol className="text-crimson-ffs font-bold">
                      {payment?.include?.map((e, i) => (
                        <li key={i}>{e}</li>
                      ))}
                    </ol>
                  </TabContent>
                ))}
                <span>Monthly Resources</span>
              </div>
            )}
          </div>
        </div>

        <div>
          <p className="mb-5 font-bold">Choose a payment plan:</p>
          <div className="grid gap-2">
            {selectedPlan.payments.map((payment) => (
              <TabLabel key={payment.name} name={payment.name}>
                <span>{payment.name}</span>
                <strong>{payment.price}</strong>
              </TabLabel>
            ))}
          </div>
          <div className="md:flex md:justify-end">
            <button
              className="w-full md:w-fit md:px-9 mt-6 py-3 with-transition lg:hover:bg-poly-cyan lg:hover:text-navy-blue-ffs text-white font-bold bg-crimson-ffs rounded-full shadow"
              onClick={onNext}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </TabWrapper>
  );
};
