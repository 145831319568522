import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateEmail } from "../../helpers/validation";
import { createContactMail } from "../../actions/contactMailActions";
import {
  CONTACTMAIL_CREATE_FAIL,
  CONTACTMAIL_CREATE_RESET,
} from "../../constants/contactMailConstants";
import Toast from "../Toast";

export const OurServicesBuyPlanForm = ({
  selectedPlan,
  selectedPayment,
  onBack,
  onNext,
}) => {
  const dispatch = useDispatch();

  const contactMailCreate = useSelector((state) => state.contactMailCreate);
  const {
    loading: sendingMail,
    error: failedToSent,
    success: mailSent,
  } = contactMailCreate;

  const [name, setName] = useState(``);
  const [email, setEmail] = useState(``);
  const [phone, setPhone] = useState(``);
  const [subject, setSubject] = useState(``);
  const [reason, setReason] = useState(``);
  const [message, setMessage] = useState(``);

  useEffect(() => {
    if (selectedPlan && selectedPayment) {
      setSubject("Request for Service");
      setReason("Collaboration in Some Business Opportunity");
      setMessage(
        `${selectedPlan.title} Plan: [ ${selectedPlan.services.join(
          ", ",
        )} ]\nSelected Tier: (${selectedPayment.name}) - ${
          selectedPayment.price
        } [ ${selectedPayment.include.join(", ")} ]`,
      );
    }
  }, [selectedPlan, selectedPayment]);

  const nameHandler = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const emailHandler = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const phoneHandler = (e) => {
    e.preventDefault();
    setPhone(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    try {
      if (
        !name?.length ||
        !email?.length ||
        !subject?.length ||
        !reason?.length ||
        !message?.length
      ) {
        throw new Error("Fields marked with an * are required!");
      }

      validateEmail(email);

      const contactMail = {
        name,
        email,
        subject,
        reason,
        message,
      };

      dispatch(createContactMail(contactMail));
    } catch (error) {
      dispatch({ type: CONTACTMAIL_CREATE_FAIL, payload: error.message });
    }
  };

  useEffect(() => {
    if (failedToSent || mailSent) {
      setTimeout(() => {
        dispatch({ type: CONTACTMAIL_CREATE_RESET });
        if (mailSent) {
          setName(``);
          setEmail(``);
          setPhone(``);
          setSubject(``);
          setReason(``);
          setMessage(``);
          window.location.reload();
        }
      }, 5000);
    }
  }, [failedToSent, mailSent, dispatch]);

  return (
    <div className="px-3 mt-11 md:mt-0 relative">
      {sendingMail ? (
        <Toast loading body className="top-[20px] md:top-[50px]" />
      ) : (
        <></>
      )}
      {mailSent ? (
        <Toast
          success
          body="Mail sent successfully"
          className="top-[20px] md:top-[50px]"
        />
      ) : (
        <></>
      )}
      {failedToSent ? (
        <Toast error body={failedToSent} className="top-[20px] md:top-[50px]" />
      ) : (
        <></>
      )}
      <button
        className={`hidden md:block z-50 sticky top-5 left-3 cursor-pointer mb-10 z-50 -mt-5 -ml-8`}
        onClick={onBack}
      >
        <i
          className={`iconify text-denim-ffs`}
          data-icon={"ep:arrow-up-bold"}
          data-rotate="-90deg"
        />
      </button>

      <form className="grid gap-5" onSubmit={submitHandler}>
        <div>
          <p className="font-bold mb-1">
            Name <span className="text-red-900">*</span>
          </p>
          <input
            type="text"
            className="border-[1px] bg-gray-ffs border-gray-border-ffs w-full rounded-lg py-2 px-4 focus:outline-none"
            onChange={nameHandler}
            value={name}
            required
          />
        </div>
        <div>
          <p className="font-bold mb-1">
            Email <span className="text-red-900">*</span>
          </p>
          <input
            type="email"
            className="border-[1px] bg-gray-ffs border-gray-border-ffs w-full rounded-lg py-2 px-4 focus:outline-none"
            onChange={emailHandler}
            value={email}
            required
          />
        </div>
        <div>
          <p className="font-bold mb-1">
            Phone <span className="text-red-900">*</span>
          </p>
          <input
            type="text"
            className="border-[1px] bg-gray-ffs border-gray-border-ffs w-full rounded-lg py-2 px-4 focus:outline-none"
            onChange={phoneHandler}
            value={phone}
            required
          />
        </div>
        <p>
          The Forager Financial Services team will contact you at their earliest
          convenience.
        </p>
        <p>
          By clicking “Submit”, I declare that the Forager International brand
          may keep me informed by sending personalized emails about products and
          services. Opt out at any time.
        </p>

        <button
          className="bg-crimson-ffs with-transition lg:hover:bg-poly-cyan lg:hover:text-navy-blue-ffs rounded-full py-3 text-white font-bold w-fit px-9"
          // onClick={onNext}
          onSubmit={submitHandler}
        >
          Submit
        </button>
      </form>
      <div>
        <p className="font-bold mt-16">Review</p>
        <div>
          <div>
            <h4 className="text-crimson-ffs mt-5">{selectedPlan.title}</h4>
            <div>
              <p>{selectedPayment.name}</p>
              <p>{selectedPayment.price}</p>
            </div>
          </div>

          <div className="my-8">
            <h4 className="text-crimson-ffs font-bold">Services:</h4>
            <ol>
              {selectedPlan?.services?.map((e, i) => (
                <li key={i}>{e}</li>
              ))}
            </ol>
          </div>
          {selectedPayment?.include?.length && (
            <div className="mb-8">
              <h4 className="font-bold text-crimson-ffs">Includes:</h4>
              {selectedPayment.include.map((e, i) => (
                <ol key={i}>
                  <li>{e}</li>
                </ol>
              ))}
              <span>Monthly Resources</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
